import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ServiceHero from "../../components/service-hero";
import PhotoSide from "../../components/photo-side";
import Testimonials from "../../components/testimonials";
import Certifications from "../../components/certifications";
import DotsCta from "../../components/dots-cta";
import BgImageCta from "../../components/bg-image-cta";

const ElectricVehicle = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: file(relativePath: { eq: "electric-car-bg.jpg" }) {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
				logoImg: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
				teslaImg: file(relativePath: { eq: "tesla-bg.jpg" }) {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: NORTH, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
				reviews {
					reviewCount
				}
				chargerImg: file(relativePath: { eq: "charger.png" }) {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);
	const reviewCount = data.reviews.reviewCount;
	const schemaImg = data.heroImg.publicURL;
	const logoImg = data.logoImg.publicURL;
	const heroImg = data.heroImg.childImageSharp.gatsbyImageData;
	const teslaImg = data.teslaImg.childImageSharp.gatsbyImageData;
	const chargerImg = data.chargerImg.childImageSharp.gatsbyImageData;
	const siteUrl = data.site.siteMetadata.siteUrl;
	const companyName = data.site.siteMetadata.company;
	const pageUrl = "/services/electric-vehicle";

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Prime Sparks",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: " Services",
				item:{ 
					url:`${siteUrl}/services`,
					id:`${siteUrl}/services`,
			},
		},
			{
				"@type": "ListItem",
				position: 3,
				name: "Electrical Vehicle Services",
				item:{ 
					url:`${siteUrl}/services/electric-vehicle`,
					id:`${siteUrl}/services/electric-vehicle`,
			},
		},
		],
	};
	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}${pageUrl}#electrical-vehicle-services`,
		image: `${siteUrl}${schemaImg}`,
		mpn: `${siteUrl}`,
		sku: `${siteUrl}${pageUrl}`,
		description: " ",
		logo: `${siteUrl}${logoImg}`,
		name: `${companyName}`,
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${logoImg}`,
			image: `${siteUrl}${schemaImg}`,
			name: `${companyName}`,
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/testimonials`,
			datePublished: "2020-09-09T17:58:29+00:00",
			reviewBody:
				"I had roger from PrimeSparks install our Electric vehicle charging point at home. Great customer service and very customer orientated. Would highly recommend PrimeSparks to anyone looking for a professional job.",
			author: {
				"@type": "Person",
				name: "Jason Roberts",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: `${companyName}`,
				sameAs: `${siteUrl}`,
			},
		},
	};
	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title="Electrical Vehicle Services in Eastbourne | Prime Sparks"
				description="Say goodbye to garage forecourt queues by charging your car at home."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Electrical Vehicle Services | Prime Sparks",
					description:
						"Say goodbye to garage forecourt queues by charging your car at home.",
					images: [
						{
							url: `${schemaImg}`,
							width: 1920,
							height: 1080,
							alt: "Electrical Vehicle Services in Eastbourne",
						},
					],
				}}
			/>
			<ServiceHero
				heading="Electrical Vehicle Services"
				text={
					<span>
						Say goodbye to garage forecourt queues by charging <br />
						your car at home.
					</span>
				}
				isDarken={true}
				image={heroImg}
			/>
			<DotsCta
				sHeading="Electric Vehicle Charger Installation in Eastbourne"
				heading={
					<span>
						Leave home with a full tank and <br />
						<span className="text-primary"> save the planet</span> at the same
						time.
					</span>
				}
				text={
					<span>
						<p>
							Whether you have a hybrid or a fully electric vehicle, or are
							considering buying one, Prime Sparks is the only electric vehicle
							charger installation specialist in Eastbourne and can come and fit
							the right charging point for your vehicle.
						</p>
						<p>
							Imagine the convenience of not having to queue at a petrol
							station, only to find that the pump has run out of fuel. Well,
							with your own personal electric vehicle charger installation
							fitted at your home, those days will soon become a thing of the
							past.
						</p>
					</span>
				}
				btnText="Get in Touch"
				btnUrl="/contact-us"
			/>
			<BgImageCta
				focus="top"
				vHeight="35rem"
				floatRight={true}
				img={teslaImg}
				heading={
					<span>
						<span className="text-primary">Get ahead of the game</span> with an
						electric vehicle charger installation
					</span>
				}
				text="With the roll-out of electric vehicles being ramped up every year, and the UK government having officially confirmed that the sales of new petrol and diesel cars will be banned from 2030, there are lots of incentives to buy a new electric vehicle. The government has also committed £20m to assist research and development competition for new electric vehicle technologies so there has never been a better time to buy an electric vehicle and have your own electric vehicle charger installation fitted by a trusted, local electrician in Eastbourne. "
				cta="CONTACT US"
				ctaUrl="/contact-us"
			/>
			<Certifications />
			<PhotoSide
				imgRight={true}
				img={chargerImg}
				height="35rem"
				heading={
					<span>
						How would you like to be the person on your street to have an{" "}
						<span className="text-primary">
							electric vehicle charging point installed
						</span>
						?
					</span>
				}
				cta="GET IN TOUCH"
				ctaUrl="/contact-us"
				text={
					<span>
						<p>
							Impress your neighbours and future generations with an
							eco-friendly, personalised EV charger installation that is always
							available and never runs out of juice.
						</p>
						<p>
							If you’re tired of your children blaming your generation for
							climate change, then there is no better way to respond than by
							having a cleaner, more environmentally-friendly vehicle and by
							having an electric vehicle charger installation fitted by the only
							specialist electrician in Eastbourne offering this service.
						</p>
					</span>
				}
			/>
			<Testimonials />
		</Layout>
	);
};
export default ElectricVehicle;
