import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";

const PhotoSide = ({
  heading,
  text,
  img,
  cta,
  ctaUrl,
  height,
  imgRight,
  showCta,
  showHr,
  sHeading,
}) => {
  const newHeight = height || "";
  const ctaVisible = showCta || "";
  const hrVisible = showHr || "";
  return (
    <section className="my-6 ">
      <Container>
        <Row className="align-items-center">
          <Col
            className="mx-lg-4"
            xs={12}
            lg={{ span: 5, order: imgRight ? "last" : "first" }}
          >
            <GatsbyImage
              style={{ maxHeight: newHeight }}
              className="w-100"
              image={img}
            />
          </Col>
          <Col xs={12} lg={6} className="px-4">
            <Row className="mb-4 mt-5 mt-lg-0">
              <Col>
                <p style={{ fontSize: "1.2rem" }} className="text-primary">
                  {sHeading}
                </p>
                <h3>{heading}</h3>
                <hr
                  style={{ width: "8rem" }}
                  className={`float-left bg-primary mt-3 ${hrVisible}`}
                />
              </Col>
            </Row>

            <p className="">{text}</p>
            <Link
              to={ctaUrl}
              className={`cta-link font-weight-bold  mt-4 ${ctaVisible}`}
            >
              {cta}
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PhotoSide;
