import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ReviewCard from "./review-card";

const Testimonials = () => {
	const [reviews, setReviews] = useState([]);

	useEffect(() => {
		axios
			.get(
				`https://www.local-marketing-reports.com/external/showcase-reviews/widgets/c223c788b26cd7c5b88a4dc08e96859695a68996`
			)
			.then((res) => {
				const reviewData = res.data.results;
				setReviews(reviewData);
				setReviews(reviewData);
				document.getElementById("loading-spinner").style.display = "none";
			});
	});

	return (
		<Container className="my-7">
			<Row className="align-items-center text-center align-middle pb-4 ">
				<Col>
					<h3>
						Our Customer <span className="text-primary">Testimonials</span>
					</h3>
					<hr className="  bg-primary mb-4" />
				</Col>
			</Row>
			<div>
				<Col>
					<Row className="px-0 mx-0" style={{ minHeight: "50vh" }}>
						<Spinner
							id="loading-spinner"
							variant="primary"
							animation="border"
							className="mt-10"
						>
							<span className="sr-only">Loading...</span>
						</Spinner>
						{reviews
							.filter((item, index) => index < 3)
							.map((review) => (
								<Col lg={4} xs={12} className="mx-0">
									<ReviewCard review={review} />
								</Col>
							))}
					</Row>
				</Col>
			</div>
			<Row>
				<Col className="text-center mt-5">
					<Link className="cta-link " to="/testimonials">
						READ ALL OF OUR TESTIMONIALS
					</Link>
				</Col>
			</Row>
		</Container>
	);
};

export default Testimonials;
