import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";
import { StaticImage } from "gatsby-plugin-image";

const DotsCta = ({ sHeading, heading, text, btnText, btnUrl, showCta }) => {
	const ctaVisible = showCta || "";
	return (
		<div>
			<Container className="py-lg-8 pb-4 pt-6">
				<Row className="align-items-center">
					<Col xs={12} md={9}>
						<Row>
							<Col>
								<p style={{ fontSize: "1.2rem" }} className="text-primary">
									{sHeading}
								</p>
								<h3>{heading}</h3>
								<hr className=" float-left bg-primary mb-4" />
							</Col>
						</Row>
						<Row>
							<Col>
								<p className="w-md-75 mb-4" style={{ fontSize: "1.1rem" }}>
									{text}
								</p>
								<Button
									as={Link}
									to={btnUrl}
									className={`text-white btn cta-btn ${ctaVisible}`}
								>
									{btnText}
								</Button>
							</Col>
						</Row>
					</Col>
					<Col
						md={{ span: 3, order: "last" }}
						className="text-center text-md-right d-none d-md-block pb-5 pb-md-0"
					>
						<StaticImage
							className=""
							src="../images/dots.svg"
							alt="dots"
							width={150}
							quality="100"
							formats={["auto", "webp"]}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default DotsCta;
