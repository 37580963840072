import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Link from "./link";

const Certifications = () => {
	return (
		<Container className=" py-md-7">
			<Row className="py-md-3">
				<Col
					xs={12}
					md={6}
					lg={3}
					className="my-5 my-md-auto px-md-6 pl-lg-0 pr-lg-5 text-center px-6"
				>
					<Link target="_blank" rel="noreferrer">
						<StaticImage
							src="../images//certifications/eca.png"
							alt="ECA"
							placeholder="tracedSVG"
							quality="100"
							formats={["auto", "webp"]}
						/>
					</Link>
				</Col>
				<Col
					xs={12}
					md={6}
					lg={3}
					className="mb-5 px-lg-4 px-md-5 px-6 my-md-auto"
				>
					<Link target="_blank" rel="noreferrer">
						<StaticImage
							src="../images//certifications/esr.png"
							alt="ESR"
							placeholder="tracedSVG"
							quality="100"
							formats={["auto", "webp"]}
						/>
					</Link>
				</Col>
				<Col
					xs={12}
					md={6}
					lg={3}
					className="mb-5 px-lg-4 px-md-5 my-md-auto px-6"
				>
					<Link target="_blank" rel="noreferrer">
						<StaticImage
							src="../images//certifications/rcp.png"
							alt="Registered Competent Person"
							placeholder="tracedSVG"
							quality="100"
							formats={["auto", "webp"]}
						/>
					</Link>
				</Col>
				<Col
					xs={12}
					md={6}
					lg={3}
					className="mb-md-5 pl-lg-5 px-md-5 pr-lg-0 my-md-auto px-6"
				>
					<Link target="_blank" rel="noreferrer">
						<StaticImage
							src="../images//certifications/rolec.png"
							alt="ROLEC"
							placeholder="tracedSVG"
							quality="100"
							formats={["auto", "webp"]}
						/>
					</Link>
				</Col>
			</Row>
		</Container>
	);
};

export default Certifications;
