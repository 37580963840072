import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const BgImageCta = ({
	img,
	text,
	heading,
	cta,
	ctaUrl,
	floatRight,
	vHeight,
	list,
	focus,
}) => {
	const pluginImage = getImage(img);
	const float = floatRight ? "justify-content-end" : "justify-content-start";

	return (
		<div div className="mt-6 pt-5 mb-4 my-md-0">
			<BgImage
				image={pluginImage}
				className="hero-services bg-cta mb-lg-10"
				style={{ backgroundPosition: focus }}
			>
				<div
					className={`h-100  d-none d-lg-flex align-items-end   ${float} `}
					style={{ minHeight: vHeight }}
				>
					<Container>
						<Col md={8} xs={10} className="mx-0 px-0">
							<div
								className={`${float}  bg-white text-overlay  px-6 pt-6 pb-4`}
							>
								{" "}
								<h3 className={`${list ? "pb-3" : "pb-4"}`}>{heading}</h3>
								<p className={`${list ? "" : "pb-4"}`}>{text}</p>
								<Link
									to={ctaUrl}
									className={`cta-link font-weight-bold  ${
										list ? "pl-2" : ""
									} `}
								>
									{cta}
								</Link>
							</div>
						</Col>
					</Container>
				</div>
			</BgImage>
			<Col xs={12} className="mt-4 px-md-4 mt-md-5 d-lg-none">
				<div className={`  bg-white  `}>
					{" "}
					<h3 className={`${list ? "pb-3" : "pb-4"}`}>{heading}</h3>
					<p className={`${list ? "" : "pb-4"}`}>{text}</p>
					<Link
						to={ctaUrl}
						className={`cta-link font-weight-bold  ${list ? "pl-2" : ""} `}
					>
						{cta}
					</Link>
				</div>
			</Col>
		</div>
	);
};

export default BgImageCta;
